import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { HeaderComponent } from './layout/header/header.component';
import { FullComponent } from './layout/full/full.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrModule } from 'ngx-toastr';
import { NavBarComponent } from './layout/nav-bar/nav-bar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SearchComponent } from '../modules/search/search.component';
// import { MonthPickerComponent } from './generic/component/month-picker/month-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ImageCropComponent } from './image-crop/image-crop.component';
import { ImageCropperComponent } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    FullComponent,
    SearchComponent,
    NavBarComponent,
    FooterComponent,
    ImageCropComponent,
    // MonthPickerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ImageCropperComponent,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    NgSelectModule,
    FormsModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatCheckboxModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    // MonthPickerComponent
    SearchComponent
  ],
})
export class SharedModule {}
