<header id="header">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-lg mobile">
      <div class="logo lap">
        <img
          src="../../../../assets/images/img/ieelogowhite.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>
      <div class="text-light pt-2 point">
        <marquee >
          Project Management & Investor Relationship Coming Soon.
        </marquee>
      </div>
      <div class="logo lap sm">
        <img
          src="../../../../assets/images/img/ieelogowhite.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>
      <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> -->
      <!-- <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->

     
      <div class="d-flex justify-content-center">

        <form class="form-inline my-2 my-lg-0">
          <app-search></app-search>
      </form>

      <div class="imgComp mx-2 d-flex align-items-center justify-content-center"
      (click)="viewProfile(userSession.id)"
      >
      <img
      class="rounded-circle img-fluid"
      [src]="userSession?.pictureFileLocations "
      alt="profile pic"
      onerror="this.onerror=null;this.src='assets/images/img/profile-avatar-1.png';"
      />
    </div>
    
  </div>
    </div>
  </nav>
</header>
<!-- <ul class="navbar-nav mx-auto">
  <li class="nav-item active">
    <a class="nav-link" href="#">Crowd Funding </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">FF Exclusive</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Exclusive</a>
  </li>
</ul> -->

<!-- 
  <div class="navbar">
    <div class="navdiv">
      <div class="content_left">
        
      <img
      src="../../../../assets/images/img/ieelogowhite.webp"
      alt=""
        [routerLink]="['/view']"
        />
        
        <h1>Crowd Funding</h1>
      <h1>FF Exclusive</h1>
      <h1>Exclusive</h1>
    </div>
    <div class="content_right">
      <div class="searchfiled">
        <div class="brand-ppinput brand-ppinput--search-right">
          <div class="brand-ppinput__search-icon">
            <em class="brand-icon brand-icon-Search"></em>
          </div>
          <input
            type="text"
            class="brand-ppinput--search-right mb-3"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="imgComp" (click)="viewProfile(userSession.id)">
        <img
          src="{{ userSession?.pictureFileLocations }}"
          alt="image"
          onerror="this.src ='../../../../assets/images/svg/profile 2.svg' "
        />
      </div>
    </div>
  </div>
</div> -->
