import { Component } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { languages, roleLists } from 'src/app/core/datas';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { SortingService } from 'src/app/core/service/sorting.service';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})

export class ProfileListComponent {
location = [
  { "country": "USA", "city": "New York" },
  { "country": "USA", "city": "Los Angeles" },
  { "country": "India", "city": "Mumbai" },
  { "country": "India", "city": "Delhi" },
  { "country": "France", "city": "Paris" },
  { "country": "France", "city": "Lyon" }
]
searchText: any;
countriesAndCities: string[] = [];
filteredResults: string[] =[];
  locations:any
  results: string[] = [];
  combinedSet = new Set<string>();
  data: any = { items: [] };
  profiles: any;
  userProfile: any;
  profileData: boolean = true;
  constructor(
    private apiHelper: ApiHelper,
    private sortService: SortingService
  ) {}

  ngOnInit(): void {
    this.getProjects();
    let userSessionString: any = localStorage.getItem('session');
    let session = JSON.parse(userSessionString);
    this.userProfile = session.profileId;
    this.apiHelper
    .post({ location: "" }, ApiEndPoints.locationSearch)
    .subscribe((response) => {
      this.data = response.data
      this.data.forEach((item: any) => {
       if (item && item.location) {
          if (item.location.country && item.location.country.trim() !== '') {
            this.combinedSet.add(item.location.country.toLowerCase());
          }
    
          if (item.location.city && item.location.city.trim() !== '') {
            this.combinedSet.add( item.location.city.toLowerCase());
          }
        }
      });
      this.countriesAndCities = Array.from(this.combinedSet);
      this.filteredResults = this.countriesAndCities
    })
  }

  getProjects(offset = 1, limit = 50) {
    this.apiHelper
      .post(
        {
          pagenation: {
            limit: limit,
            offset: offset,
          },
        },
        ApiEndPoints.profileFind,
        true
      )
      .subscribe((response) => {
        this.profiles = response.data.filter(
          (item: any) => item.id !== this.userProfile
        );
        if (response.data?.length === limit) {
          this.profileData = true;
        } else {
          this.profileData = false;
        }
      });
  }
  count = 1;
  pagenation(type: string) {
    if (type === 'next') this.count++;
    if (type === 'previous' && this.count > 1) {
      this.count--;
    }
    this.getProjects(this.count);
  }

  searchQuery : any = {
    freeText: '',
    role: '',
    location: '',
  };


  searchFilter(type: string, event: any) {
    let data = event.target.value
    this.searchQuery[type] = data;
    console.log(data)
    this.apiHelper
      .post({ filters: this.searchQuery }, ApiEndPoints.search)
      .subscribe((response) => {
        this.profiles = response.data.items.filter(
          (item: any) => item.id !== this.userProfile
        );
        if (response.data?.length === 50) {
          this.profileData = true;
        } else {
          this.profileData = false;
        }
      });
  }

  roleLists = this.sortService.sortArrayAlphabetically(roleLists);

  

onCancel(){
  this.getProjects()
}
}
