<h2>Please crop your image</h2>
<mat-dialog-content>
<image-cropper
[maintainAspectRatio]="true"
[onlyScaleDown]="true"
format="jpeg"
[imageFile]="data.file"
(imageCropped)="imageCropped($event)">
</image-cropper>
</mat-dialog-content>   
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [mat-dialog-close]="result()" cdkFocusInitial>
      Done
    </button>