<div class="col-md-5 search-container" (mouseout)="focusOnList=false">
  <input class="form-control-ff mr-sm-2" type="text" [(ngModel)]="searchQuery" (input)="onSearch()" placeholder="Search"
    aria-label="Search" />

  <div class="search-results" *ngIf="searchResults.length">
    <ul>
      <li *ngFor="let result of searchResults" (click)="onProfileClick(result.id)">
        {{ result.name }}
      </li>
    </ul>
  </div>
</div>