import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  focusOnList: boolean = true;
  searchQuery: string = '';
  searchResults: any[] = [];
  data: any = { items: [] };

  constructor(private apiHelper: ApiHelper, private router: Router) {}

  onSearch(): void {
    if (this.searchQuery) {
      this.apiHelper
        .post({ freeText: this.searchQuery }, ApiEndPoints.search)
        .subscribe(
          (response) => {
            this.data = response.data;
            if (this.data && this.data.items) {
              this.searchResults = this.data.items.filter((item: any) =>
                item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              );
            } else {
              this.searchResults = [];
            }
          },
          (error) => {
            console.error('Error fetching search results', error);
            this.searchResults = [];
          }
        );
    } else {
      this.searchResults = [];
    }
  }

  onProfileClick(id: string) {
    this.searchQuery = '';
    this.searchResults = [];

    this.router.navigate(['view/profile/view', id]);
  }
}
