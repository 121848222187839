<div class="parent clearfix">
  <div class="bg-illustration">
    <img src="assets/images/img/ieelogowhite.webp" alt="logo" />
  </div>
  <div class="login">
    <div class="gradient-bg"></div>
    <div class="container  height-100vh d-flex flex-column align-items-center justify-content-center p-4 pb-0">
      <div class="login-form bg-primary-400 w-100 p-5 pb-2" *ngIf="loginAccount">
        <div class="">
          <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
            <div class="mb-2">
              <input
                class="form-control"
                type="text"
                formControlName="phoneNumber"
                placeholder="Phone Number"
              />
              <span
                *ngIf="
                  loginForm.invalid && (loginForm.dirty || loginForm.touched)
                "
                class="text-danger"
              >
                <span *ngIf="loginForm.errors">This field is required</span>
              </span>
            </div>
            <div class="mb-2">
              <input
                class="form-control"
                type="password"
                formControlName="password"
                placeholder="Password"
              />
            </div>
            <div class="mb-3">
              <div class="d-flex justify-content-center">
                <p
                  class="text-white cursor-hand font-md m-0"
                  (click)="accountChange()"
                >
                  Signup Account
                </p>
              </div>
            </div>
            <div class="mb-2 cursor-hand">
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-outline-dark text-white btn-grad-ff"
                  [disabled]="loginForm.invalid"
                >
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="login-form bg-primary-400 w-100 p-5 pb-2" *ngIf="signinAccount">
        <div class="">
          <form [formGroup]="signUpForm" (ngSubmit)="onSignUpSubmit()">
            <div class="mb-2">
              <input
                class="form-control"
                type="text"
                formControlName="phoneNumber"
                placeholder="Mobile Number"
              />
            </div>
            <div class="mb-2">
              <input
                class="form-control"
                type="password"
                formControlName="password"
                placeholder="password"
              />
            </div>
            <div class="mb-2">
              <input
                class="form-control"
                type="password"
                formControlName="confirmPassword"
                placeholder="Confirm Password"
              />
            </div>
            <div class="row">
              <div class="col-md-6 mb-2">
                <input
                  class="form-control"
                  type="text"
                  formControlName="firstName"
                  placeholder="First Name"
                />
              </div>
              <div class="col-md-6 mb-2">
                <input
                  class="form-control"
                  type="text"
                  formControlName="lastName"
                  placeholder="Second Name"
                />
              </div>
            </div>

            <div class="mb-3">
              <div class="d-flex justify-content-center">
                <p
                  class="text-white cursor-hand font-md m-0"
                  (click)="accountChange()"
                >
                  Login Account
                </p>
              </div>
            </div>
            <div class="mb-2 cursor-hand">
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-outline-dark text-white btn-grad-ff"
                  type="submit"
                  [disabled]="signUpForm.invalid"
                >
                  Signup
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex justify-content-center bg-primary-400 pb-5 w-100">
        <div id="buttonDiv"></div>
      </div>

    </div>
  
  </div>
</div>
