<div class="smallHeader">
  <div class="container-lg">
    <!-- <div class="row p-1 p-md-5 align-items-center rounded" style="background-image:url(https://images.pexels.com/photos/7130560/pexels-photo-7130560.jpeg);background-size:cover"> -->
    <div class="row align-items-center rounded container-img">
      <div class="col-md-5">
        <h1 class="title point">Spotlight Your Story!</h1>
        <div class="row">
          <div class="col-md-8">
            <p class="text-light desc point">
              Create your profile with your personal journey, projects,
              achievements, and awards. Let the world see your work, connect
              with industry professionals, and unlock exclusive features that
              celebrate your unique cinematic path.
            </p>
          </div>
        </div>

        <div class="d-flex flex-wrap gap-2 mb-3 mb-md-0">
          <button
            class="btn btn-outline-light mt-3"
            [routerLink]="['profile/view', userProfile]"
          >
            Your Profile
          </button>
        </div>
      </div>
      <!-- <div class="col-md-6 text-center">
                <img src="assets/images/spotlight.jpg" class="img-fluid rounded"
                    style="max-width: 300px; max-height: 300px;" alt="">
            </div> -->
    </div>
  </div>
</div>

<div class="container-lg mt-5">
  <div class="row">
    <div class="col-lg-8 col-sm-12 col-md-6 ps-2">
      <h1>Profile List</h1>
    </div>

    <div class="col-lg-4 col-sm-12 col-md-6 mb-4">
      <div class="d-flex justify-content-end mobile">
        <div class="pe-2">

          <select
          class="ps-2 form-control-ff"
          id="locationsSelect"
          (change)="searchFilter('location', $event)"
          >
          <option value="" style=" display: none;">location</option>
          <option *ngFor="let location of filteredResults" [value]="location">
            {{ location }}
          </option>
        </select>
      </div>
        

        <select
          class="ps-2 form-control-ff"
          id="rolesSelect"
          (change)="searchFilter('role', $event)"
        ><option value=""style=" display: none;">role</option>
          <option class="dropdown-value" *ngFor="let role of roleLists" [value]="role">
            {{ role }}
          </option>
        </select>

        <button class="btn btn-primary ms-2" (click)="onCancel()">
          Clear
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col-lg-4 col-md-6 col-sm-12 col-xl-4 mb-3"
      *ngFor="let item of profiles"
      [routerLink]="['profile/view', item.id]"
    >
      <div
        class="card-wrap bg-ff border border-dark rounded p-3 featured-project-list"
      >
        <div
          class="d-flex justify-content-between align-items-center mb-3"
        ></div>
        <div class="project-pic">
          <img
            class="rounded-circle img-fluids"
            [src]="item.pictureFileLocations"
            onerror="this.onerror=null;this.src='assets/images/img/profile-avatar-1.png';"
            alt="Card image cap"
          />
        </div>
        <p class="mb-2"><strong>Name :</strong> {{ item.name }}</p>
        <p class="mb-2"><strong>City :</strong> {{ item?.location?.city }}</p>
        <p class="mb-2">
          <strong>country :</strong> {{ item?.location?.country }}
        </p>
        <p class="mb-2"><strong>Role :</strong>{{ item?.industryRole }}</p>
        <a class="btn btn-grad-ff mt-2" [routerLink]="['profile/view', item.id]"
          >View More</a
        >
      </div>
    </div>
  </div>
</div>

<div class="pagination">
  <button
    class="pagination-button"
    *ngIf="count != 1"
    (click)="pagenation('previous')"
  >
    &laquo; Prev
  </button>
  <button
    class="pagination-button"
    *ngIf="profileData"
    (click)="pagenation('next')"
  >
    Next &raquo;
  </button>
</div>
